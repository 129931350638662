import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "curve",
  preserveAspectRatio: "none",
  viewBox: "0 0 300 120"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M300 120H0q0-180 0 0C0 0 300 0 300 120V0",
      class: "curve__path"
    }, null, -1)
  ])))
}
export default { render: render }